import * as React from "react";
import Header from "../common/components/organism/Header";
import Footer from "../common/components/organism/Footer";
import { HeroSection as Hero } from "@subtropico/shared";


const IndexPage = () => (
    <>
        <Header />
        <Hero />
        <Footer />
    </>
);

export default IndexPage;
